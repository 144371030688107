// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
    /*
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  */
}

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
      require(`intersection-observer`)
      console.log(`# IntersectionObserver is polyfilled!`)
    }
  }